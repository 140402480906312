
import "./checkbox-v1"
import { CF2Component } from 'javascript/lander/runtime'

export default class CheckoutTosV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

mount() { 
    const input = this.element.querySelector('input')

    input.addEventListener('change', (event) => {
      Checkout.store.tos.accepted.set(event.target.checked)
    })

  }



}

window["CheckoutTosV1"] = CheckoutTosV1

