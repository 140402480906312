const STATES_WITH_POSTAL_CODE = new Set(['US', 'GB', 'CA', 'FI', 'FR', 'IT', 'ES'])
import { cleanEmptyObjectKeys } from 'src/Elements/Utils/general'

import { Address, AddressFields, SavedAddress } from '../types'

export const backfillAddressId = (address: Address): Address => {
  if (address.id) return address
  let addressId = 'no-id#'
  addressId += [address.country, address.state, address.city, address.zip, address.address, address.address_2]
    .filter(Boolean)
    .join(':')

  return { ...address, id: addressId }
}

export const backfillAddressesId = (addresses: Address[]): Address[] => {
  return addresses.map((address) => backfillAddressId(address))
}

export const isSavedAddress = (address: Address): address is SavedAddress => {
  return address.id && !String(address.id).includes('no-id#')
}

export const fieldsForCountry = (fields: AddressFields, country: string): AddressFields => {
  const fieldsLength = Object.keys(fields).length
  if (fieldsLength > 0 && fieldsLength <= 2) {
    const hasPostalCode = STATES_WITH_POSTAL_CODE.has(country)
    if (hasPostalCode) {
      return ['country', 'zip']
    } else {
      return ['country']
    }
  }
  return fields
}

export const addressToString = (address: Address): string => {
  let str = ''
  if (address.address) {
    str = address.address
    if (address.address2) {
      str += `, ${address.address2}`
    }
    if (address.city) {
      str += `. ${address.city}`
    }
    if (address.state) {
      str += `, ${address.state}`
    }
    if (address.zip) {
      str += ` ${address.zip}`
    }
    if (address.country) {
      str += `, ${address.country}`
    }
  } else {
    if (address.zip) {
      str += `${address.zip}, `
    }
    if (address.country) {
      str += `${address.country}`
    }
  }
  return str
}

export const parseAddressByFields = (address: Address, fields: AddressFields, shouldBackfill = true): Address => {
  const addressByFields = fields.reduce((acc, field) => {
    acc[field] = address[field] ?? (shouldBackfill ? globalThis.Checkout.contactLocale[field] : null)
    return acc
  }, {}) as Address

  return cleanEmptyObjectKeys({ ...addressByFields, id: address.id })
}
