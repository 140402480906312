
import "./checkout-coupon-form-v1"
import { CheckoutSummary } from '@yggdrasil/Checkout/V2/Services/checkout-summary'
import { closeOnClickOutside, addPageScroll, removePageScroll } from 'javascript/lander/cf_utils'
import { Cart } from '@yggdrasil/Cart'
import { hasPhysicalProducts } from '@yggdrasil/Checkout/V2/Services/checkout-utils'
import { CF2Component } from 'javascript/lander/runtime'

export default class CheckoutOrderSummaryV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}






  initializeWithStore(store, checkoutComputed) {
    this.checkoutStore = store
    ;[
      checkoutComputed.checkoutCart, 
      this.checkoutStore.summary
    ].forEach((store) => {
      store.listen(() => {
        this.renderSummaryDetails()
      })
    })

    if (Checkout.store.payment) {
      nanostores.computed([Checkout.store.payment?.type], (paymentType) => paymentType == 'apple-pay')
        .subscribe(isDigitalWalletPaymentType => {
          if (isDigitalWalletPaymentType && !this.couponFieldEnabled) {
            this.hide()
          } else {
            this.show()
          }
        })
    }
    
    this.renderSummaryDetails()

    Checkout.store.state.listen((state) => {
      if (state === Checkout.StoreStates.FILLING_FORM) {
        this.renderSummaryDetails()
      }
    })

    if (globalResourceData.resourceName == 'checkout' && this.mobileBehavior != null) {
      closeOnClickOutside(this.element, () => {
        addPageScroll()
        this.element.classList.remove('elOrderSummaryMobileOpen')
      })
    }
  }

  show() {
    this.element.classList.remove('elHide');
  }

  hide() {
    this.element.classList.add('elHide');
  }

  renderSummaryDetails() {
    const summary = this.checkoutStore.summary.get() 
    this.state = summary.state

    this.summary = !summary.data ? {} : summary.data
    const is_checkout_page = globalResourceData.resourceName == 'checkout'
    const cart = is_checkout_page ? Cart.stores.cartData.get() : Checkout.computed.checkoutCart.get()
    this.hasPhysicalProducts = hasPhysicalProducts(cart)
    const cartItems = cart.items
    this.items = cartItems.map(({ product_id, variant_id, price_id, quantity }) => {
      const variant = Checkout.variantsById[variant_id]
      const price = Checkout.pricesById[price_id]
      const product = Checkout.productsById[product_id]
      const variantNamesArray = variant.property_value_ids.map((property_value_id) => {
        return product.all_properties_values[property_value_id]
      })
      return {
        productName: is_checkout_page ? product.name : variant.name,
        image_url: variant.image ?? product.image,
        priceName: price.name,
        imageUrl: variant.image ?? variant.images?.[0] ?? product.image ?? product.images?.[0],
        variantNames: variantNamesArray.length > 0 ? variantNamesArray.join(", ") : undefined,
        quantity
      }
    })

    let couponForm = this.getComponent('CheckoutCouponForm/V1')
    couponForm?.cleanup(this.checkoutStore)
    this.render(true)
    this.hydrate()
    couponForm = this.getComponent('CheckoutCouponForm/V1')
    couponForm?.hydrate(this.checkoutStore)
  }

  hydrate() {
    this.element.querySelector('.elOrderSummaryHead')?.addEventListener('click', (evt) => {
      evt.preventDefault();
      this.open = !this.open
      this.renderSummaryDetails()
    })
    this.element.querySelector('.elOrderSummaryCouponClear')?.addEventListener('click', (evt) => {
      evt.preventDefault();
      this.checkoutStore.coupons.currentCode.set("")
      this.checkoutStore.coupons.appliedCode.set("")
      CheckoutSummary.sendOrderPreview()
    })
    this.element.querySelector('.elOrderSummaryHeader')?.addEventListener('click', () => {
      if (this.element.classList.contains('elOrderSummaryMobileOpen')) {
        addPageScroll()
        this.element.classList.remove('elOrderSummaryMobileOpen')
      } else {
        removePageScroll()
        this.element.classList.add('elOrderSummaryMobileOpen')
      }
    })
  }


    remove() {
      this.element.innerHTML = '';
    }
    render(initializeChildrenInstances = false) {
      const is_server = true;
      const couponFieldEnabled = this.couponFieldEnabled ?? null ;
const open = this.open ?? null ;
const state = this.state ?? "ok" ;
const items = this.items ?? [] ;
const summary = this.summary ?? {} ;
const hasPhysicalProducts = this.hasPhysicalProducts ?? false ;
const billingFields = this.billingFields ?? null ;
const shippingFields = this.shippingFields ?? null ;
const mobileBehavior = this.mobileBehavior ?? null ;
const summaryTexts = this.summaryTexts ?? null ;

    const linkWithCheckout = this.element.getAttribute('data-param-linkWithCheckout');
const linkedCheckoutId = this.element.getAttribute('data-param-linkedCheckoutId');
  
      let html = '';
      {
        if (globalResourceData.resourceName == "checkout") {
          if (linkWithCheckout) {
            html += `<div class="elCheckoutWrapper">`
            if (linkWithCheckout == "true" || linkWithCheckout == true) {
              html += `<div class="elCheckoutOuterFrame"></div>`
            }
            const is_checkout_page = globalResourceData.resourceName == "checkout";
            if (is_checkout_page) {
              html += `<div class="elOrderSummaryHeader`
              if (!mobileBehavior || !true) {
                html += ` forceHide`
              }
              html += `"><div class="elOrderSummaryHeaderClosed"><div><span>View Order Summary</span><i class="fas fa-chevron-down"></i></div>`
              if (state == "ok") {
                html += `<span class="elOrderSummaryHeaderTotal">`
                if (summary.interim) {
                html += `${summary.interim.formatted}`
                } else {
                html += `${summary.total.formatted}`
                }
                html += `</span>`
              }
              html += `</div><div class="elOrderSummaryHeaderOpenned"><span>Close Order Summary</span><i class="fas fa-chevron-up"></i></div></div>`
              html += `<div class="elOrderSummaryBody elCheckoutSummaryResource"><div class="elCartOrderSummaryItems">`
              const c0 = items
              const fl1 = new CF2ForloopDrop(c0.length)
              for (const item of c0) {
                const forloop = fl1
                html += `<div class="elCartOrderSummaryRow"><div class="elCartOrderSummaryRowLeft">`
                const imageUrl = item.imageUrl;
                html += `<div class="elCartOrderSummaryProductImageContainer `
                if (!imageUrl) {
                  html += `elCartOrderSummaryEmptyImageContainer`
                }
                html += `">`
                if (imageUrl) {
                  html += `<div data-page-element="Image/V2" class="elImageWrapper de-image-block id-Image/V2`
                  if (true && !imageUrl && !null && false == false) {
                    html += ` forceHide`
                  }
                  html += `" data-liquid-replace="item">`
                  if (imageUrl || !!null) {
                    html += `<img class="elImage" src="${imageUrl ?? null}"/>`
                  } else if (false) {
                    html += `<div class="image-placeholder" title="This element will render with content once configured."><span class="image-placeholder-header">[Replaced by Content]</span></div>`
                  }
                  html += `</div>`
                }
                if (item.quantity && item.quantity > 0) {
                  html += `<div class="elCartOrderSummaryProductImageBadge">${item.quantity}</div>`
                }
                html += `</div><div class="elCartOrderSummaryRowLeftTextContainer"><div class="elCartOrderSummaryWithImageSubRow elOrderSummaryProductNameRow">${item.productName}</div>`
                if (item.variantNames) {
                  html += `<div class="elCartOrderSummaryWithImageSubRow elOrderSummaryProductDescriptionRow">${item.variantNames}</div>`
                }
                html += `</div></div><div class="elCartOrderSummaryRowRight"><span>${item.priceName}</span></div></div>`
                forloop.next();
              }
              html += `</div><div class="elOrderSummaryTotals">`
              if (couponFieldEnabled && couponFieldEnabled != "false") {
                html += `<hr class="elCartOrderSummaryDivider"/>`
                html += `<div data-page-element="CheckoutCouponForm/V1" class="elCheckoutCouponFormContainer id-CheckoutCouponForm/V1 hide" data-liquid-replace="item">`
                const is_checkout_page = globalResourceData.resourceName == "checkout";
                if (is_checkout_page) {
                  html += `<div class="elCheckoutCartCouponFormWrapper"><div data-page-element="Input/V1" class="elFormItemWrapper elInputWrapper de-input-block elCheckoutCartCouponCodeInput id-Input/V1 labelInside" type="coupon_codes" data-liquid-replace="item"><input class="elFormItem elInput inputHolder " value="" type="text" name="coupon_codes" pattern="" autocomplete="" inputtype="" data-prevent-submit="" data-prevent-garlic="true" placeholder="Discount Code" maxlength=""/><div class="elLabel"><div class="borderHolder"></div><div class="borderHolder labelText"><label>Discount Code</label></div><div class="borderHolder"></div></div><div class="inputHolder" data-input-status-type="true" data-error-container="true"></div><div class="input-icon"><i class="fas fa-exclamation"></i></div></div><div class="elCheckoutCartCouponCodeButtonWrapper"><div data-page-element="Button/V1" class="elBTN elCheckoutCartCouponCodeButton id-Button/V1" data-liquid-replace="item"><a target="_self" href="#check-coupon-code" class="elButton" aria-label="" data-disabled="false" data-show-button-ids="" data-hide-button-ids="" data-on-submit-go-to="" data-param-submittingText="Submitting..." data-style-guide-button="" rel="noopener"><span class="elButtonMain"><i class="fas fa-spinner fa-spin elButtonSpinner elButtonText"></i><span class="elButtonMainText elButtonText">Add</span></span><span class="elButtonSub"></span></a></div><span class="elCheckoutCouponCodeButtonLoading"><i style="margin:0px 10px;" class="fas fa-spinner fa-spin"></i></span></div></div>`
                } else {
                  html += `<div class="elCheckoutCouponFormWrapper"><i data-page-element="IconNode" class="fa-fw fa_icon fa_prepended elCheckoutCouponTagIcon fa fa-tag id-IconNode"></i><div data-page-element="Input/V1" class="elFormItemWrapper elInputWrapper de-input-block elCheckoutCouponCodeInput id-Input/V1 labelInside" type="coupon_codes" data-liquid-replace="item"><input class="elFormItem elInput inputHolder " value="" type="text" name="coupon_codes" pattern="" autocomplete="" inputtype="" data-prevent-submit="" data-prevent-garlic="true" placeholder="Enter Coupon" maxlength=""/><div class="elLabel"><div class="borderHolder"></div><div class="borderHolder labelText"><label>Enter Coupon</label></div><div class="borderHolder"></div></div><div class="inputHolder" data-input-status-type="true" data-error-container="true"></div><div class="input-icon"><i class="fas fa-exclamation"></i></div></div><div class="elCheckoutCouponCodeButtonWrapper"><div data-page-element="Button/V1" class="elBTN elCheckoutCouponCodeButton id-Button/V1" data-liquid-replace="item"><a target="_self" href="#check-coupon-code" class="elButton" aria-label="" data-disabled="false" data-show-button-ids="" data-hide-button-ids="" data-on-submit-go-to="" data-param-submittingText="Submitting..." data-style-guide-button="" rel="noopener"><span class="elButtonMain"><i class="fas fa-spinner fa-spin elButtonSpinner elButtonText"></i><span class="elButtonMainText elButtonText">Apply</span></span><span class="elButtonSub"></span></a></div><span class="elCheckoutCouponCodeButtonLoading"><i style="margin:0px 10px;" class="fas fa-spinner fa-spin"></i></span></div></div>`
                }
                html += `<paragraph-v1 class="elCouponCodeError"></paragraph-v1></div>`
              }
              html += `<hr class="elCartOrderSummaryDivider"/><div class="elOrderSummaryRow"><div class="elOrderSummaryRowLeft"> Subtotal </div><div class="elOrderSummaryRowRight">`
              if (summary.subtotal) {
              html += `${summary.subtotal.formatted}`
              } else {
                html += `--`
              }
              html += `</div></div>`
              if (summary.discount && summary.discount.amount > 0 && summary.discounts.length > 0) {
                const c2 = summary.discounts
                const fl3 = new CF2ForloopDrop(c2.length)
                for (const discount of c2) {
                  const forloop = fl3
                  html += `<div class="elOrderSummaryRow elOrderSummaryDiscountRow"><div class="elOrderSummaryRowLeft"> Discount `
                  if (discount.code) {
                    html += `(`
                    html += `${discount.code})`
                  }
                  html += `<span class="elOrderSummaryCouponClear">X</span></div><div class="elOrderSummaryRowRight">${summary.discount.formatted}</div></div>`
                  forloop.next();
                }
              }
              if (hasPhysicalProducts) {
                html += `<div class="elOrderSummaryRow"><div class="elOrderSummaryRowLeft"> Shipping </div><div class="elOrderSummaryRowRight">`
                if (!summary || !summary.shipping || !summary.shipping.amount) {
                  html += ` Calculated when shipping is entered `
                } else if (summary.shipping.amount > 0) {
                html += `${summary.shipping.formatted}`
                } else {
                  html += ` FREE `
                }
                html += `</div></div>`
              }
              html += `<hr class="elCartOrderSummaryDivider"/><div class="elOrderSummaryRow"><div class="elCartOrderSummaryRowLeftTextContainer"><div class="elCartOrderSummarySubRow elCartOrderSummarySubRowTotalText"> Total </div>`
              if (summary.tax && summary.tax.amount > 0) {
                html += `<div class="elCartOrderSummarySubRow elCartOrderSummarySubRowTaxesText"> including ${summary.tax.formatted} in taxes </div>`
              }
              html += `</div><div class="elCartOrderSummaryRowRight"><span class="elCartOrderSummarySubRowTotalPrice">`
              if (summary.interim) {
              html += `${summary.interim.formatted}`
              } else {
                if (summary.total) {
                html += `${summary.total.formatted}`
                } else {
                  html += `--`
                }
              }
              html += `</span></div></div></div></div>`
            } else {
              html += `<div class="elOrderSummaryHead"><div class="elOrderSummaryRow"><div class="elOrderSummaryRowLeft"><i class="fa fa-shopping-basket"></i><span class="elOrderSummaryHeadText">${summaryTexts.title}</span></div><div class="elOrderSummaryRowRight">`
              if (state == "waiting") {
                html += `<span> ${summaryTexts.details} <i style="margin:0px 10px;" class="fas fa-spinner fa-spin"></i></span>`
              } else if (state == "calculating") {
                html += `<span> ${summaryTexts.calculating} <i style="margin:0px 10px;" class="fas fa-spinner fa-spin"></i></span>`
              } else if (state == "error") {
                html += `<span> ${summaryTexts.error} <i style="margin:0px 10px;" class="fas fa-spinner fa-spin"></i></span>`
              }
              if (open) {
                html += `<i class="fas fa-chevron-up elOrderSummaryToggleable"></i>`
              } else {
                if (state == "ok") {
                  html += `<span class="elOrderSummaryTotalPrice">`
                  if (summary.interim) {
                  html += `${summary.interim.formatted}`
                  } else {
                  html += `${summary.total.formatted}`
                  }
                  html += `</span>`
                }
                html += `<i class="fas fa-chevron-down elOrderSummaryToggleable"></i>`
              }
              html += `</div></div></div>`
              if (open) {
                if (couponFieldEnabled && couponFieldEnabled != "false") {
                  html += `<div data-page-element="CheckoutCouponForm/V1" class="elCheckoutCouponFormContainer id-CheckoutCouponForm/V1 hide" data-liquid-replace="item">`
                  const is_checkout_page = globalResourceData.resourceName == "checkout";
                  if (is_checkout_page) {
                    html += `<div class="elCheckoutCartCouponFormWrapper"><div data-page-element="Input/V1" class="elFormItemWrapper elInputWrapper de-input-block elCheckoutCartCouponCodeInput id-Input/V1 labelInside" type="coupon_codes" data-liquid-replace="item"><input class="elFormItem elInput inputHolder " value="" type="text" name="coupon_codes" pattern="" autocomplete="" inputtype="" data-prevent-submit="" data-prevent-garlic="true" placeholder="Discount Code" maxlength=""/><div class="elLabel"><div class="borderHolder"></div><div class="borderHolder labelText"><label>Discount Code</label></div><div class="borderHolder"></div></div><div class="inputHolder" data-input-status-type="true" data-error-container="true"></div><div class="input-icon"><i class="fas fa-exclamation"></i></div></div><div class="elCheckoutCartCouponCodeButtonWrapper"><div data-page-element="Button/V1" class="elBTN elCheckoutCartCouponCodeButton id-Button/V1" data-liquid-replace="item"><a target="_self" href="#check-coupon-code" class="elButton" aria-label="" data-disabled="false" data-show-button-ids="" data-hide-button-ids="" data-on-submit-go-to="" data-param-submittingText="Submitting..." data-style-guide-button="" rel="noopener"><span class="elButtonMain"><i class="fas fa-spinner fa-spin elButtonSpinner elButtonText"></i><span class="elButtonMainText elButtonText">Add</span></span><span class="elButtonSub"></span></a></div><span class="elCheckoutCouponCodeButtonLoading"><i style="margin:0px 10px;" class="fas fa-spinner fa-spin"></i></span></div></div>`
                  } else {
                    html += `<div class="elCheckoutCouponFormWrapper"><i data-page-element="IconNode" class="fa-fw fa_icon fa_prepended elCheckoutCouponTagIcon fa fa-tag id-IconNode"></i><div data-page-element="Input/V1" class="elFormItemWrapper elInputWrapper de-input-block elCheckoutCouponCodeInput id-Input/V1 labelInside" type="coupon_codes" data-liquid-replace="item"><input class="elFormItem elInput inputHolder " value="" type="text" name="coupon_codes" pattern="" autocomplete="" inputtype="" data-prevent-submit="" data-prevent-garlic="true" placeholder="Enter Coupon" maxlength=""/><div class="elLabel"><div class="borderHolder"></div><div class="borderHolder labelText"><label>Enter Coupon</label></div><div class="borderHolder"></div></div><div class="inputHolder" data-input-status-type="true" data-error-container="true"></div><div class="input-icon"><i class="fas fa-exclamation"></i></div></div><div class="elCheckoutCouponCodeButtonWrapper"><div data-page-element="Button/V1" class="elBTN elCheckoutCouponCodeButton id-Button/V1" data-liquid-replace="item"><a target="_self" href="#check-coupon-code" class="elButton" aria-label="" data-disabled="false" data-show-button-ids="" data-hide-button-ids="" data-on-submit-go-to="" data-param-submittingText="Submitting..." data-style-guide-button="" rel="noopener"><span class="elButtonMain"><i class="fas fa-spinner fa-spin elButtonSpinner elButtonText"></i><span class="elButtonMainText elButtonText">Apply</span></span><span class="elButtonSub"></span></a></div><span class="elCheckoutCouponCodeButtonLoading"><i style="margin:0px 10px;" class="fas fa-spinner fa-spin"></i></span></div></div>`
                  }
                  html += `<paragraph-v1 class="elCouponCodeError"></paragraph-v1></div>`
                }
                html += `<div class="elOrderSummaryBody">`
                const c4 = items
                const fl5 = new CF2ForloopDrop(c4.length)
                for (const item of c4) {
                  const forloop = fl5
                  html += `<div class="elOrderSummaryRow"><div class="elOrderSummaryRowLeft">${item.productName}</div><div class="elOrderSummaryRowRight">`
                  if (item.quantity && item.quantity > 1) {
                    html += `<span class="elOrderSummaryQuantity">Qty ${item.quantity}</span>`
                  }
                  html += `<span>${item.priceName}</span></div></div>`
                  forloop.next();
                }
                if (state == "ok") {
                  if (summary.upcoming_invoice && summary.upcoming_invoice.line_items.length) {
                    html += `<hr class="elOrderSummaryDivider"/>`
                    const c6 = summary.upcoming_invoice.line_items
                    const fl7 = new CF2ForloopDrop(c6.length)
                    for (const item of c6) {
                      const forloop = fl7
                      html += `<div class="elOrderSummaryRow"><div class="elOrderSummaryRowLeft">${item.variant_name}</div><div class="elOrderSummaryRowRight">`
                      if (item.quantity && item.quantity > 1) {
                        html += `<span class="elOrderSummaryQuantity">Qty ${item.quantity}</span>`
                      }
                      html += `<span>${item.formatted}</span></div></div>`
                      forloop.next();
                    }
                  }
                  html += `<hr class="elOrderSummaryDivider"/>`
                  html += `<div class="elOrderSummaryRow"><div class="elOrderSummaryRowLeft">${summaryTexts.taxes}</div><div class="elOrderSummaryRowRight">`
                  if (summary.shipping.amount >= 0) {
                  html += `${summary.tax.formatted}`
                  } else {
                    html += ` -- `
                  }
                  html += `</div></div>`
                  if (summary.discount.amount > 0 && summary.discounts.length > 0) {
                    const c8 = summary.discounts
                    const fl9 = new CF2ForloopDrop(c8.length)
                    for (const discount of c8) {
                      const forloop = fl9
                      html += `<div class="elOrderSummaryRow elOrderSummaryDiscountRow"><div class="elOrderSummaryRowLeft">${summaryTexts.discount} `
                      if (discount.code) {
                        html += `(`
                        html += `${discount.code})`
                      }
                      html += `<span class="elOrderSummaryCouponClear">X</span></div><div class="elOrderSummaryRowRight">${summary.discount.formatted}</div></div>`
                      forloop.next();
                    }
                  }
                  if (hasPhysicalProducts) {
                    html += `<div class="elOrderSummaryRow"><div class="elOrderSummaryRowLeft">${summaryTexts.shipping}</div><div class="elOrderSummaryRowRight">`
                    if (summary.shipping.amount > 0) {
                    html += `${summary.shipping.formatted}`
                    } else if (summary.shipping.amount <= 0) {
                    html += `${summaryTexts.free}`
                    } else {
                      html += ` -- `
                    }
                    html += `</div></div>`
                  }
                  html += `<hr class="elOrderSummaryDivider"/>`
                  html += `<div class="elOrderSummaryRow"><div class="elOrderSummaryRowLeft">${summaryTexts.total}</div><div class="elOrderSummaryRowRight"><span class="elOrderSummaryTotalPrice">`
                  if (summary.interim) {
                  html += `${summary.interim.formatted}`
                  } else {
                  html += `${summary.total.formatted}`
                  }
                  html += `</span></div></div>`
                }
                html += `</div>`
              }
            }
            html += `</div>`
          }
        } else {
          html += `<div class="elCheckoutWrapper">`
          if (linkWithCheckout == "true" || linkWithCheckout == true) {
            html += `<div class="elCheckoutOuterFrame"></div>`
          }
          const is_checkout_page = globalResourceData.resourceName == "checkout";
          if (is_checkout_page) {
            html += `<div class="elOrderSummaryHeader`
            if (!mobileBehavior || !true) {
              html += ` forceHide`
            }
            html += `"><div class="elOrderSummaryHeaderClosed"><div><span>View Order Summary</span><i class="fas fa-chevron-down"></i></div>`
            if (state == "ok") {
              html += `<span class="elOrderSummaryHeaderTotal">`
              if (summary.interim) {
              html += `${summary.interim.formatted}`
              } else {
              html += `${summary.total.formatted}`
              }
              html += `</span>`
            }
            html += `</div><div class="elOrderSummaryHeaderOpenned"><span>Close Order Summary</span><i class="fas fa-chevron-up"></i></div></div>`
            html += `<div class="elOrderSummaryBody elCheckoutSummaryResource"><div class="elCartOrderSummaryItems">`
            const c10 = items
            const fl11 = new CF2ForloopDrop(c10.length)
            for (const item of c10) {
              const forloop = fl11
              html += `<div class="elCartOrderSummaryRow"><div class="elCartOrderSummaryRowLeft">`
              const imageUrl = item.imageUrl;
              html += `<div class="elCartOrderSummaryProductImageContainer `
              if (!imageUrl) {
                html += `elCartOrderSummaryEmptyImageContainer`
              }
              html += `">`
              if (imageUrl) {
                html += `<div data-page-element="Image/V2" class="elImageWrapper de-image-block id-Image/V2`
                if (true && !imageUrl && !null && false == false) {
                  html += ` forceHide`
                }
                html += `" data-liquid-replace="item">`
                if (imageUrl || !!null) {
                  html += `<img class="elImage" src="${imageUrl ?? null}"/>`
                } else if (false) {
                  html += `<div class="image-placeholder" title="This element will render with content once configured."><span class="image-placeholder-header">[Replaced by Content]</span></div>`
                }
                html += `</div>`
              }
              if (item.quantity && item.quantity > 0) {
                html += `<div class="elCartOrderSummaryProductImageBadge">${item.quantity}</div>`
              }
              html += `</div><div class="elCartOrderSummaryRowLeftTextContainer"><div class="elCartOrderSummaryWithImageSubRow elOrderSummaryProductNameRow">${item.productName}</div>`
              if (item.variantNames) {
                html += `<div class="elCartOrderSummaryWithImageSubRow elOrderSummaryProductDescriptionRow">${item.variantNames}</div>`
              }
              html += `</div></div><div class="elCartOrderSummaryRowRight"><span>${item.priceName}</span></div></div>`
              forloop.next();
            }
            html += `</div><div class="elOrderSummaryTotals">`
            if (couponFieldEnabled && couponFieldEnabled != "false") {
              html += `<hr class="elCartOrderSummaryDivider"/>`
              html += `<div data-page-element="CheckoutCouponForm/V1" class="elCheckoutCouponFormContainer id-CheckoutCouponForm/V1 hide" data-liquid-replace="item">`
              const is_checkout_page = globalResourceData.resourceName == "checkout";
              if (is_checkout_page) {
                html += `<div class="elCheckoutCartCouponFormWrapper"><div data-page-element="Input/V1" class="elFormItemWrapper elInputWrapper de-input-block elCheckoutCartCouponCodeInput id-Input/V1 labelInside" type="coupon_codes" data-liquid-replace="item"><input class="elFormItem elInput inputHolder " value="" type="text" name="coupon_codes" pattern="" autocomplete="" inputtype="" data-prevent-submit="" data-prevent-garlic="true" placeholder="Discount Code" maxlength=""/><div class="elLabel"><div class="borderHolder"></div><div class="borderHolder labelText"><label>Discount Code</label></div><div class="borderHolder"></div></div><div class="inputHolder" data-input-status-type="true" data-error-container="true"></div><div class="input-icon"><i class="fas fa-exclamation"></i></div></div><div class="elCheckoutCartCouponCodeButtonWrapper"><div data-page-element="Button/V1" class="elBTN elCheckoutCartCouponCodeButton id-Button/V1" data-liquid-replace="item"><a target="_self" href="#check-coupon-code" class="elButton" aria-label="" data-disabled="false" data-show-button-ids="" data-hide-button-ids="" data-on-submit-go-to="" data-param-submittingText="Submitting..." data-style-guide-button="" rel="noopener"><span class="elButtonMain"><i class="fas fa-spinner fa-spin elButtonSpinner elButtonText"></i><span class="elButtonMainText elButtonText">Add</span></span><span class="elButtonSub"></span></a></div><span class="elCheckoutCouponCodeButtonLoading"><i style="margin:0px 10px;" class="fas fa-spinner fa-spin"></i></span></div></div>`
              } else {
                html += `<div class="elCheckoutCouponFormWrapper"><i data-page-element="IconNode" class="fa-fw fa_icon fa_prepended elCheckoutCouponTagIcon fa fa-tag id-IconNode"></i><div data-page-element="Input/V1" class="elFormItemWrapper elInputWrapper de-input-block elCheckoutCouponCodeInput id-Input/V1 labelInside" type="coupon_codes" data-liquid-replace="item"><input class="elFormItem elInput inputHolder " value="" type="text" name="coupon_codes" pattern="" autocomplete="" inputtype="" data-prevent-submit="" data-prevent-garlic="true" placeholder="Enter Coupon" maxlength=""/><div class="elLabel"><div class="borderHolder"></div><div class="borderHolder labelText"><label>Enter Coupon</label></div><div class="borderHolder"></div></div><div class="inputHolder" data-input-status-type="true" data-error-container="true"></div><div class="input-icon"><i class="fas fa-exclamation"></i></div></div><div class="elCheckoutCouponCodeButtonWrapper"><div data-page-element="Button/V1" class="elBTN elCheckoutCouponCodeButton id-Button/V1" data-liquid-replace="item"><a target="_self" href="#check-coupon-code" class="elButton" aria-label="" data-disabled="false" data-show-button-ids="" data-hide-button-ids="" data-on-submit-go-to="" data-param-submittingText="Submitting..." data-style-guide-button="" rel="noopener"><span class="elButtonMain"><i class="fas fa-spinner fa-spin elButtonSpinner elButtonText"></i><span class="elButtonMainText elButtonText">Apply</span></span><span class="elButtonSub"></span></a></div><span class="elCheckoutCouponCodeButtonLoading"><i style="margin:0px 10px;" class="fas fa-spinner fa-spin"></i></span></div></div>`
              }
              html += `<paragraph-v1 class="elCouponCodeError"></paragraph-v1></div>`
            }
            html += `<hr class="elCartOrderSummaryDivider"/><div class="elOrderSummaryRow"><div class="elOrderSummaryRowLeft"> Subtotal </div><div class="elOrderSummaryRowRight">`
            if (summary.subtotal) {
            html += `${summary.subtotal.formatted}`
            } else {
              html += `--`
            }
            html += `</div></div>`
            if (summary.discount && summary.discount.amount > 0 && summary.discounts.length > 0) {
              const c12 = summary.discounts
              const fl13 = new CF2ForloopDrop(c12.length)
              for (const discount of c12) {
                const forloop = fl13
                html += `<div class="elOrderSummaryRow elOrderSummaryDiscountRow"><div class="elOrderSummaryRowLeft"> Discount `
                if (discount.code) {
                  html += `(`
                  html += `${discount.code})`
                }
                html += `<span class="elOrderSummaryCouponClear">X</span></div><div class="elOrderSummaryRowRight">${summary.discount.formatted}</div></div>`
                forloop.next();
              }
            }
            if (hasPhysicalProducts) {
              html += `<div class="elOrderSummaryRow"><div class="elOrderSummaryRowLeft"> Shipping </div><div class="elOrderSummaryRowRight">`
              if (!summary || !summary.shipping || !summary.shipping.amount) {
                html += ` Calculated when shipping is entered `
              } else if (summary.shipping.amount > 0) {
              html += `${summary.shipping.formatted}`
              } else {
                html += ` FREE `
              }
              html += `</div></div>`
            }
            html += `<hr class="elCartOrderSummaryDivider"/><div class="elOrderSummaryRow"><div class="elCartOrderSummaryRowLeftTextContainer"><div class="elCartOrderSummarySubRow elCartOrderSummarySubRowTotalText"> Total </div>`
            if (summary.tax && summary.tax.amount > 0) {
              html += `<div class="elCartOrderSummarySubRow elCartOrderSummarySubRowTaxesText"> including ${summary.tax.formatted} in taxes </div>`
            }
            html += `</div><div class="elCartOrderSummaryRowRight"><span class="elCartOrderSummarySubRowTotalPrice">`
            if (summary.interim) {
            html += `${summary.interim.formatted}`
            } else {
              if (summary.total) {
              html += `${summary.total.formatted}`
              } else {
                html += `--`
              }
            }
            html += `</span></div></div></div></div>`
          } else {
            html += `<div class="elOrderSummaryHead"><div class="elOrderSummaryRow"><div class="elOrderSummaryRowLeft"><i class="fa fa-shopping-basket"></i><span class="elOrderSummaryHeadText">${summaryTexts.title}</span></div><div class="elOrderSummaryRowRight">`
            if (state == "waiting") {
              html += `<span> ${summaryTexts.details} <i style="margin:0px 10px;" class="fas fa-spinner fa-spin"></i></span>`
            } else if (state == "calculating") {
              html += `<span> ${summaryTexts.calculating} <i style="margin:0px 10px;" class="fas fa-spinner fa-spin"></i></span>`
            } else if (state == "error") {
              html += `<span> ${summaryTexts.error} <i style="margin:0px 10px;" class="fas fa-spinner fa-spin"></i></span>`
            }
            if (open) {
              html += `<i class="fas fa-chevron-up elOrderSummaryToggleable"></i>`
            } else {
              if (state == "ok") {
                html += `<span class="elOrderSummaryTotalPrice">`
                if (summary.interim) {
                html += `${summary.interim.formatted}`
                } else {
                html += `${summary.total.formatted}`
                }
                html += `</span>`
              }
              html += `<i class="fas fa-chevron-down elOrderSummaryToggleable"></i>`
            }
            html += `</div></div></div>`
            if (open) {
              if (couponFieldEnabled && couponFieldEnabled != "false") {
                html += `<div data-page-element="CheckoutCouponForm/V1" class="elCheckoutCouponFormContainer id-CheckoutCouponForm/V1 hide" data-liquid-replace="item">`
                const is_checkout_page = globalResourceData.resourceName == "checkout";
                if (is_checkout_page) {
                  html += `<div class="elCheckoutCartCouponFormWrapper"><div data-page-element="Input/V1" class="elFormItemWrapper elInputWrapper de-input-block elCheckoutCartCouponCodeInput id-Input/V1 labelInside" type="coupon_codes" data-liquid-replace="item"><input class="elFormItem elInput inputHolder " value="" type="text" name="coupon_codes" pattern="" autocomplete="" inputtype="" data-prevent-submit="" data-prevent-garlic="true" placeholder="Discount Code" maxlength=""/><div class="elLabel"><div class="borderHolder"></div><div class="borderHolder labelText"><label>Discount Code</label></div><div class="borderHolder"></div></div><div class="inputHolder" data-input-status-type="true" data-error-container="true"></div><div class="input-icon"><i class="fas fa-exclamation"></i></div></div><div class="elCheckoutCartCouponCodeButtonWrapper"><div data-page-element="Button/V1" class="elBTN elCheckoutCartCouponCodeButton id-Button/V1" data-liquid-replace="item"><a target="_self" href="#check-coupon-code" class="elButton" aria-label="" data-disabled="false" data-show-button-ids="" data-hide-button-ids="" data-on-submit-go-to="" data-param-submittingText="Submitting..." data-style-guide-button="" rel="noopener"><span class="elButtonMain"><i class="fas fa-spinner fa-spin elButtonSpinner elButtonText"></i><span class="elButtonMainText elButtonText">Add</span></span><span class="elButtonSub"></span></a></div><span class="elCheckoutCouponCodeButtonLoading"><i style="margin:0px 10px;" class="fas fa-spinner fa-spin"></i></span></div></div>`
                } else {
                  html += `<div class="elCheckoutCouponFormWrapper"><i data-page-element="IconNode" class="fa-fw fa_icon fa_prepended elCheckoutCouponTagIcon fa fa-tag id-IconNode"></i><div data-page-element="Input/V1" class="elFormItemWrapper elInputWrapper de-input-block elCheckoutCouponCodeInput id-Input/V1 labelInside" type="coupon_codes" data-liquid-replace="item"><input class="elFormItem elInput inputHolder " value="" type="text" name="coupon_codes" pattern="" autocomplete="" inputtype="" data-prevent-submit="" data-prevent-garlic="true" placeholder="Enter Coupon" maxlength=""/><div class="elLabel"><div class="borderHolder"></div><div class="borderHolder labelText"><label>Enter Coupon</label></div><div class="borderHolder"></div></div><div class="inputHolder" data-input-status-type="true" data-error-container="true"></div><div class="input-icon"><i class="fas fa-exclamation"></i></div></div><div class="elCheckoutCouponCodeButtonWrapper"><div data-page-element="Button/V1" class="elBTN elCheckoutCouponCodeButton id-Button/V1" data-liquid-replace="item"><a target="_self" href="#check-coupon-code" class="elButton" aria-label="" data-disabled="false" data-show-button-ids="" data-hide-button-ids="" data-on-submit-go-to="" data-param-submittingText="Submitting..." data-style-guide-button="" rel="noopener"><span class="elButtonMain"><i class="fas fa-spinner fa-spin elButtonSpinner elButtonText"></i><span class="elButtonMainText elButtonText">Apply</span></span><span class="elButtonSub"></span></a></div><span class="elCheckoutCouponCodeButtonLoading"><i style="margin:0px 10px;" class="fas fa-spinner fa-spin"></i></span></div></div>`
                }
                html += `<paragraph-v1 class="elCouponCodeError"></paragraph-v1></div>`
              }
              html += `<div class="elOrderSummaryBody">`
              const c14 = items
              const fl15 = new CF2ForloopDrop(c14.length)
              for (const item of c14) {
                const forloop = fl15
                html += `<div class="elOrderSummaryRow"><div class="elOrderSummaryRowLeft">${item.productName}</div><div class="elOrderSummaryRowRight">`
                if (item.quantity && item.quantity > 1) {
                  html += `<span class="elOrderSummaryQuantity">Qty ${item.quantity}</span>`
                }
                html += `<span>${item.priceName}</span></div></div>`
                forloop.next();
              }
              if (state == "ok") {
                if (summary.upcoming_invoice && summary.upcoming_invoice.line_items.length) {
                  html += `<hr class="elOrderSummaryDivider"/>`
                  const c16 = summary.upcoming_invoice.line_items
                  const fl17 = new CF2ForloopDrop(c16.length)
                  for (const item of c16) {
                    const forloop = fl17
                    html += `<div class="elOrderSummaryRow"><div class="elOrderSummaryRowLeft">${item.variant_name}</div><div class="elOrderSummaryRowRight">`
                    if (item.quantity && item.quantity > 1) {
                      html += `<span class="elOrderSummaryQuantity">Qty ${item.quantity}</span>`
                    }
                    html += `<span>${item.formatted}</span></div></div>`
                    forloop.next();
                  }
                }
                html += `<hr class="elOrderSummaryDivider"/>`
                html += `<div class="elOrderSummaryRow"><div class="elOrderSummaryRowLeft">${summaryTexts.taxes}</div><div class="elOrderSummaryRowRight">`
                if (summary.shipping.amount >= 0) {
                html += `${summary.tax.formatted}`
                } else {
                  html += ` -- `
                }
                html += `</div></div>`
                if (summary.discount.amount > 0 && summary.discounts.length > 0) {
                  const c18 = summary.discounts
                  const fl19 = new CF2ForloopDrop(c18.length)
                  for (const discount of c18) {
                    const forloop = fl19
                    html += `<div class="elOrderSummaryRow elOrderSummaryDiscountRow"><div class="elOrderSummaryRowLeft">${summaryTexts.discount} `
                    if (discount.code) {
                      html += `(`
                      html += `${discount.code})`
                    }
                    html += `<span class="elOrderSummaryCouponClear">X</span></div><div class="elOrderSummaryRowRight">${summary.discount.formatted}</div></div>`
                    forloop.next();
                  }
                }
                if (hasPhysicalProducts) {
                  html += `<div class="elOrderSummaryRow"><div class="elOrderSummaryRowLeft">${summaryTexts.shipping}</div><div class="elOrderSummaryRowRight">`
                  if (summary.shipping.amount > 0) {
                  html += `${summary.shipping.formatted}`
                  } else if (summary.shipping.amount <= 0) {
                  html += `${summaryTexts.free}`
                  } else {
                    html += ` -- `
                  }
                  html += `</div></div>`
                }
                html += `<hr class="elOrderSummaryDivider"/>`
                html += `<div class="elOrderSummaryRow"><div class="elOrderSummaryRowLeft">${summaryTexts.total}</div><div class="elOrderSummaryRowRight"><span class="elOrderSummaryTotalPrice">`
                if (summary.interim) {
                html += `${summary.interim.formatted}`
                } else {
                html += `${summary.total.formatted}`
                }
                html += `</span></div></div>`
              }
              html += `</div>`
            }
          }
          html += `</div>`
        }

      }

      this.element.innerHTML = html;

      if (initializeChildrenInstances) {
        CF2Component.hydrateTree(this.element);
      }
    }


}

window["CheckoutOrderSummaryV1"] = CheckoutOrderSummaryV1

