export const renderedPath = (): string => {
  const renderedPage = document.cookie
    .split('; ')
    .find((x) => x.match(/^cfhoy_rendered_page=/))
    ?.split('=')[1]
  return renderedPage ?? window.location.pathname
}

export const renderedHref = (extraPath?: string): string => {
  const url = new URL(window.location.href)
  url.pathname = renderedPath() + (extraPath ?? '')
  return url.toString()
}
