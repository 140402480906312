// NOTE: not sure what TODO here.
import { CFErrorWithCause, CFstackWithCauses } from 'javascript/lander/utils/error_with_cause'
import { CFFetcherError } from 'javascript/lander/utils/fetcher'

export class CheckoutCriticalError extends CFErrorWithCause {
  name: string
  constructor(message: string, options: { cause: Error }) {
    super(message, options)
    this.name = 'CheckoutCriticalError'
  }
}

export const ERROR_CODES = {
  EMPTY_PRODUCTS_ERROR: 'EMPTY_PRODUCTS_ERROR',
  EMPTY_PAYMENT_METHODS_ERROR: 'EMPTY_PAYMENT_METHODS_ERROR',
  UNEXPECTED_INITIALIZATION_ERROR: 'UNEXPECTED_INITIALIZATION_ERROR',
  FETCH_FEATURE_FLAGS_ERROR: 'FETCH_FEATURE_FLAGS_ERROR',
  FETCH_COUNTRIES_STATES_ERROR: 'FETCH_COUNTRIES_STATES_ERROR',
  PAI_INITIALIZATION_SCRIPT_ERROR: 'PAI_INITIALIZATION_SCRIPT_ERROR',
  PAI_INITIALIZATION_TIMEOUT_ERROR: 'PAI_INITIALIZATION_TIMEOUT_ERROR',
  PAI_INITIALIZATION_READY_TIMEOUT_ERROR: 'PAI_INITIALIZATION_READY_TIMEOUT_ERROR',
  FETCH_EXISTING_ORDERS_ERROR: 'FETCH_EXISTING_ORDERS_ERROR',
  FETCH_PHONE_UTILS_ERROR: 'FETCH_PHONE_UTILS_ERROR',
  ORDER_RECURRING_INTERVAL_MISMATCH: 'ORDER_RECURRING_INTERVAL_MISMATCH',
}

export const displayAndLogCriticalError = (checkoutCriticalError: CheckoutCriticalError): void => {
  const criticalErrors = globalThis.Checkout.store.criticalErrors.get()
  const originalError = checkoutCriticalError.cause
  if (originalError instanceof CFFetcherError) {
    const fetchError = originalError
    globalThis.Checkout.store.criticalErrors.set([
      ...criticalErrors,
      { code: checkoutCriticalError.message, fetchErrorType: fetchError.type },
    ])
  } else {
    globalThis.Checkout.store.criticalErrors.set([...criticalErrors, { code: checkoutCriticalError.message }])
  }
  // NOTE: this prints the stack traces with all causes in the console and also sends it to bugsnag
  // we need to render then separately here because the stacktrace showing all error causes is still
  // not supported in browsers. So this call does the job of implementing it
  console.error(CFstackWithCauses(checkoutCriticalError))
  globalThis.Bugsnag?.notify(checkoutCriticalError)
}
