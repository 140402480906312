
import "./checkout-product-card-bump-v2"
import { registerOneTimeCardElementListeners, registerEventListeners, updateCardByProductIdState, renderAndMount  } from '@yggdrasil/Checkout/V2/CheckoutProductSelect/V2/checkoutProductSelect'
import { CF2Component } from 'javascript/lander/runtime'

export default class CheckoutProductSelectBumpV2 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}



  mount() {
    const productCardComponents = this.getComponents("CheckoutProductCardBump/V2");

    registerOneTimeCardElementListeners(productCardComponents)
    productCardComponents.forEach((pcc) => {
      registerEventListeners(pcc)
      this.registerBumpVariantEventListeners(pcc)
    })
  }

  registerBumpVariantEventListeners(pcc){
    const element = pcc.element
    const variantSelects = element.querySelectorAll('.elBumpVariantSelector')

    variantSelects.forEach((select, index) => {
      select.addEventListener('click', (evt) => {
        evt.stopImmediatePropagation()
        evt.stopPropagation()
        evt.preventDefault()
      })

      select.addEventListener('change', (evt) => {
        evt.stopImmediatePropagation()
        evt.stopPropagation()
        evt.preventDefault()

        const newValues = [...variantSelects].map((e) => e.value)

        const selectedVariantId = pcc.product.property_values_variant_mapping[newValues]
        const oldCartItem = {product_id: pcc.product.id, variant_id: pcc.variant.id, price_id: pcc.selected_price.id}
        const newVariant = pcc.product.variants.find((v) => v.id == String(selectedVariantId))
        const newPrice = newVariant.prices[0]
        const newCartItem = {product_id: pcc.product.id, variant_id: newVariant.id, price_id: newPrice.id}

        updateCardByProductIdState(pcc.product.id, {variantId: newVariant.id, priceId: newPrice.id})
        pcc.render()
      })
    })
  }



}

window["CheckoutProductSelectBumpV2"] = CheckoutProductSelectBumpV2

