
 import  { listenKeys } from "nanostores"

import "./checkout-product-card-v2"
import { registerOneTimeCardElementListeners, registerEventListeners, registerProductEventListeners } from '@yggdrasil/Checkout/V2/CheckoutProductSelect/V2/checkoutProductSelect'
import { CF2Component } from 'javascript/lander/runtime'

export default class CheckoutProductSelectV2 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}



  mount() {
    const productCardComponents = this.getComponents("CheckoutProductCard/V2");
    registerOneTimeCardElementListeners(productCardComponents, (pcc) => { registerProductEventListeners(pcc) })
    productCardComponents.forEach((pcc) => {
      registerEventListeners(pcc, (pcc) => { registerProductEventListeners(pcc) })
    })
  }



}

window["CheckoutProductSelectV2"] = CheckoutProductSelectV2

